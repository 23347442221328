import { useTranslation } from "react-i18next";
import { getCookie } from "~/old-app/utils/helpers/cookieManager";
import {
  MdCube,
  FillHeartFill,
  AccountBalanceWallet,
  LocationOn,
  UserAlt,
  FillInfoCircle,
  Message,
  AssignmentReturn,
  HomeOutline,
  Apartment,
  BuildingHouse,
  Stars,
} from "~/old-app/assets/icons";

export const asideLinks = () => {
  const { t } = useTranslation();
  const enableQuotations = getCookie("selectedServiceQuotation") === "true";
  return [
    {
      title: t("account.influencer"),
      links: [
        {
          icon: <MdCube className="text-white" size={16} />,
          text: t("account.influencerOrders"),
          path: "influencer-orders",
        },
        {
          icon: <AssignmentReturn className="text-white" size={16} />,
          text: t("account.influencerCoupon"),
          path: "influencer-coupon",
        },

        {
          icon: <FillHeartFill className="text-white" size={16} />,
          text: t("account.influencerWishlist"),
          path: "influencer-wishlist",
        },
        // {
        //   icon: <AccountBalanceWallet className="text-white" size={16} />,
        //   text: t("account.influencerSellProducts"),
        //   path: "influencer-sell-products",
        // },
      ],
    },

    {
      title: t("account.orders"),
      links: [
        {
          icon: <MdCube className="text-white" size={16} />,
          text: t("account.myOrders"),
          path: "my-orders",
        },

        // Conditionally add the myQuotations link only if enableQuotations is true
        ...(enableQuotations
          ? [
              {
                icon: <MdCube className="text-white" size={16} />,
                text: t("account.myQuotations"),
                path: "my-quotations",
              },
            ]
          : []), // If false, return an empty array so nothing is added
        {
          icon: <AssignmentReturn className="text-white" size={16} />,
          text: t("account.returnOrders"),
          path: "return-orders",
        },

        {
          icon: <FillHeartFill className="text-white" size={16} />,
          text: t("account.myFavourites"),
          path: "my-favourites",
        },
        // {
        //   icon: <AccountBalanceWallet className="text-white" size={16} />,
        //   text: t("account.myWallet"),
        //   path: "my-wallet",
        // },
      ],
    },
    {
      title: t("account.personalInformation"),
      links: [
        {
          icon: <LocationOn className="text-white" size={16} />,
          text: t("account.myAddress"),
          path: "my-address",
        },
        {
          icon: <UserAlt className="text-white" size={16} />,
          text: t("account.profile"),
          path: "profile",
        },
      ],
    },
    // {
    //   title: t("account.points"),
    //   links: [
    //     {
    //       icon: <Stars className="text-white" size={16} />,
    //       text: t("account.loyaltyPoints"),
    //       path: "loyalty-points",
    //     },
    //     {
    //       icon: <Stars className="text-white" size={16} />,
    //       text: t("account.referralProgram"),
    //       path: "referral-program",
    //     },
    //     // {
    //     //   icon:<MdMegaphone className="text-white" size={16} />,
    //     //   txt: {t("account.affiliateProgram"),
    //     //   path:"affiliate-program"
    //     // },
    //   ],
    // },
  ];
};
export const asidePublicLinks = (app_name) => {
  const { t } = useTranslation();

  return [
    // {
    //   title: {t("account.settings"),
    //   links: [
    //     //{
    //     //  icon: <Language className="text-white" size={16} />,
    //    //  text: {t("account.country"),
    //     //  path: "country",
    //     //},
    //     // {
    //     //   icon: <GTranslate className="text-white" size={16} />,
    //    //   text: {t("account.preferences"),
    //     //   path: "preferences",
    //     // },
    //   ],
    // },
    {
      title: app_name,
      links: [
        {
          icon: <FillInfoCircle className="text-white" size={16} />,
          text: t("account.aboutAbout"),
          path: "pages/aboutus",
        },
        {
          icon: <Message className="text-white" size={16} />,
          text: t("account.contactUs"),
          path: "contact-us",
        },
        // {
        //   icon: <Message className="text-white" size={16} />,
        //   text: t("account.support"),
        //   path: "support",
        // },
      ],
    },
  ];
};
export const orderStatus = () => {
  const { t } = useTranslation();

  return [
    {
      text: t("account.current"),
      key: "current",
    },
    {
      text: t("account.completed"),
      key: "completed",
    },
    {
      text: t("account.rejected"),
      key: "rejected",
    },
    // {
    //   txt: {t("account.cancellations"),
    //   key: "cancelled",
    // },
    // {
    //   txt: {t("account.return"),
    //   key: "return",
    // },
  ];
};
export const returnOrderStatus = () => {
  const { t } = useTranslation();

  return [
    {
      text: t("account.current"),
      key: "current",
    },
    {
      text: t("account.completed"),
      key: "returned",
    },
  ];
};
export const refundMethodOptions = (t) => {
  return [
    {
      id: "wallet",
      text: t("account.walletMethod"),
      icon: AccountBalanceWallet,
    },
  ];
};
export const addressTypes = () => {
  const { t } = useTranslation();

  return [
    {
      id: "home",
      name: t("account.home"),
      icon: HomeOutline,
    },
    {
      id: "work",
      name: t("account.work"),
      icon: Apartment,
    },
    {
      id: "other",
      name: t("account.other"),
      icon: BuildingHouse,
    },
  ];
};

export const genderTypes = () => {
  const { t } = useTranslation();

  return [
    {
      id: "female",
      name: t("account.female"),
    },
    {
      id: "male",
      name: t("account.male"),
    },
  ];
};
export const languageTypes = () => {
  const { t } = useTranslation();

  return [
    {
      id: "ar",
      name: t("global.arabic"),
    },
    {
      id: "en",
      name: t("global.english"),
    },
  ];
};
